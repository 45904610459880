<template>
  <span
    v-if="isVisible"
    :class="tripServiceIcon"
    :title="service.name"
    class="fas m-l-5 be-text-primary"
  >
  </span>
</template>

<script>
export default {
  name: "TripServiceIcon",
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isVisible() {
      return this.service.availalibilty === 1;
    },
    tripServiceIcon() {
      switch (this.service.key) {
        case "food_service":
          return "fa-coffee";
        case "toilet_service":
          return "fa-question";
        case "luggage_service":
          return "fa-shopping-bag";
        case "tv_service":
          return "fa-tv";
        default:
          return this.service.icon;
      }
    },
  },
};
</script>

<style scoped></style>
