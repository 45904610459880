<template>
  <div
    class="d-flex align-items-center"
    :class="
      smallSize
        ? 'flex-row-reverse justify-content-end ml-0'
        : 'justify-content-center ml-4'
    "
  >
    <span class="be-switch-label op-8">
      {{ agency.agency }}
    </span>
    <div
      class="switch be-switch"
      :class="smallSize ? 'ml-0 mr-3': 'ml-4'"
    >
      <input
        type="checkbox"
        :id="`switch-agency-${index}`"
        :checked="agency.state"
        @change="changeAgencySate(index)"
      />
      <label :for="`switch-agency-${index}`" class="cr mb-0"></label>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AgencyCheckbox',
  props: {
    agency: {
      type: Object,
      required: true,
    },
    index: Number,
    smallSize: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    ...mapActions('shopping', ['changeAgencySate']),
  },
};
</script>

<style scoped></style>
