<template>
  <div class="shopping-trip-card">
    <router-link
      :to="{
        name: 'shopping.form.process',
        params: { ...$route.params, token_trip: this.travel.token_trip },
      }"
    >
      <div class="shopping-card-title">
        <div class="shopping-price">
          {{ travel.per_seat_fare }}
          <span>{{ currency }}</span>
        </div>
        <img :src="travel.logo_agency" class="img-fluid rounded shadow-sm" />
      </div>
      <div class="row-table text-muted">
        <div class="col pl-1 pr-1">
          <span class="shopping-card-row-text">
            <i class="icon-location row-icon" />
            {{ travel.departure_city }} ({{ travel.departure_location }}),
            {{ departureDatetime | moment("H") }}:{{
              arrivalDatetime | moment("mm")
            }}
          </span>

          <div class="shopping-card-row-placeholder"></div>

          <span class="shopping-card-row-text">
            <i class="icon-target row-icon" />
            {{ travel.arrival_city }} ({{ travel.arrival_location }}),
            {{ (travel.date + " " + travel.arrival_time) | moment("H") }}:{{
              (travel.date + " " + travel.arrival_time) | moment("mm")
            }}
          </span>

          <div class="shopping-card-row-placeholder d-flex ">
            <i class="icon-arrow-right d-block ml-auto"></i>
          </div>

          <span class="shopping-card-row-text">
            <i class="icon-calendar row-icon" />
            {{ travel.date | moment("dddd, Do MMMM YYYY") }}
          </span>

          <div class="shopping-card-row-placeholder"></div>

          <div class="shopping-card-row-text">
            <i class="icon-stopwatch row-icon" />
            Expire
            <span class="text-lowercase">
              {{ travel.end_of_online_sales | moment("from", "now") }}
            </span>
          </div>

          <div class="shopping-card-row-placeholder"></div>

          <span class="d-flex justify-content-between shopping-card-row-text">
            <div class="d-flex align-items-center">
              <i class="icon-user row-icon" />
              {{ travel.seat_available_count }}
              {{
                travel.seat_available_count > 1
                  ? $t("utils.places")
                  : $t("utils.place")
              }}
            </div>
            <div class="col-auto text-left pr-1 d-flex align-items-center">
              <i
                :class="{ 'icon-sun': !isNight, 'icon-moon': isNight }"
                class="opacity-5"
              ></i>
              <StandingTripItem :standing="travel.standing_trip" />
            </div>
          </span>
        </div>
      </div>
      <div class="row justify-content-center mt-3 text-c-blue">
        <template v-if="isAvailableService">
          <TripServiceIcon
            v-for="(service, index) in travel.services_trip"
            :key="index"
            :service="service"
          />
        </template>
        <span v-else class="opacity-7">{{ $t("utils.no_service") }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
import TripServiceIcon from "./TripServiceIcon";
import { travelTripMixin } from "../../mixins/travelTripMixin";
import StandingTripItem from "./StandingTripItem";

export default {
  name: "ShoppingTripCard",
  components: { StandingTripItem, TripServiceIcon },
  mixins: [travelTripMixin],
};
</script>

<style scoped></style>
