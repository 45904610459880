<template>
  <div
    :class="smallSize ? 'flex-column' : ''"
    class="be-row align-items-center p-3 p-sm-4 shopping-trip-filter"
  >
    <div
      class="be-row justify-content-md-start justify-content-center"
      :class="`${getWidth}`"
    >
      <router-link
        :class="getWidth"
        :to="{ name: 'shopping.form', params: $route.params }"
      >
        <button
          class="be-btn p-md-auto secondary blue lg w-100"
          :class="`${getWidth} ${isTextVisible ? 'circle-btn' : ''}`"
        >
          <i class="icon-arrow-left" />
          <span :class="!isTextVisible ? 'd-inline' : 'd-none'">Retour</span>
        </button>
      </router-link>
    </div>
    <div
      class="be-row justify-content-start pr-4"
      :class="smallSize ? 'flex-column w-100' : ''"
    >
      <AgencyCheckbox
        v-for="(agency, index) in agencies"
        :index="index"
        :key="index"
        :agency="agency"
        :smallSize="smallSize"
      />
    </div>
    <div class="be-row justify-content-start col-xl-auto px-0">
      <div
        class="pr-sm-4 col-12 col-sm-6 col-lg-3 px-0 shopping-trip-filter-select"
      >
        <be-multiselect
          placeholder="Type de ticket"
          :options="ticketTypes"
          v-model="value.trip"
          label="label"
          onlyKey
          trackBy="value"
          @change="
            $emit('input', { trip: $event.target.value, day: value.day })
          "
        />
      </div>
      <div class="col-12 col-sm-6 col-lg-3 px-0 shopping-trip-filter-select">
        <be-multiselect
          placeholder="Période"
          :options="tourType"
          v-model="value.day"
          label="label"
          onlyKey
          trackBy="value"
          @change="
            $emit('input', { day: $event.target.value, trip: value.trip })
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AgencyCheckbox from "./AgencyCheckbox";
import BeMultiselect from "../../components/common/BeMultiselect";
export default {
  name: "ShoppingFilterTrip",
  components: { AgencyCheckbox, BeMultiselect },
  props: {
    value: [Object],
    service: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ticketTypes: [
        { label: "Tous", value: null },
        { label: "VIP", value: "vip" },
        { label: "Classic", value: "classic" },
      ],
      tourType: [
        { label: "Tous", value: null },
        { label: "Jour", value: "day" },
        { label: "Nuit", value: "night" },
      ],
    };
  },
  computed: {
    ...mapState("shopping", ["agencies"]),
    smallSize() {
      return this.$screen.width < 576;
    },
    getWidth() {
      return this.smallSize ? "w-100" : "";
    },
    isTextVisible() {
      return this.$screen.width < 768 && this.$screen.width > 576;
    },
  },
};
</script>

<style scoped></style>
