<template>
  <div class="be-row justify-content-center h-100">
    <div class="col-lg-12 p-1">
      <div class="be-card h-100">
        <header-with-divider :text="headerText" color="blue" />
        <div class="h-100 d-flex flex-column">
          <ShoppingFilterTrip v-model="filter" :service="service" />
          <div class="divider d-none d-md-block" />
          <div v-if="travels.length" class="shopping-trip-card-container">
            <div class="h-100 scroll-wrapper">
              <div class="scroll-content">
                <div
                  class="max-height-1px be-row justify-content-center p-md-4 px-0 shopping-cards"
                >
                  <div
                    v-for="travel in travels"
                    :key="travel.token_trip"
                    class="pr-md-4 pr-3 col-xl-5 pt-4"
                  >
                    <ShoppingTripCard :travel="travel" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BaseEmptyList v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShoppingFilterTrip from "../../components/shopping/ShoppingFilterTrip";
import ShoppingTripCard from "../../components/shopping/ShoppingTripCard";
import HeaderWithDivider from "../../components/common/HeaderWithDivider";

export default {
  name: "ShoppingFormTravels",
  components: { ShoppingTripCard, ShoppingFilterTrip, HeaderWithDivider },
  props: {
    serviceType: {
      type: Object,
      required: true,
    },
    service: {
      type: Object,
      default: null,
    },
    searchField: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      filter: {
        trip: null,
        day: null,
      },
    };
  },

  computed: {
    ...mapGetters("shopping", ["filterTravels"]),

    travels() {
      return this.filterTravels(this.filter.trip, this.filter.day);
    },
    headerText() {
      return (
        this.$t("shopping.travel_result_of_departure") +
        " " +
        this.searchField.depart +
        " " +
        this.$t("common.for") +
        " " +
        this.searchField.arrive +
        " " +
        this.$t("common.to") +
        " " +
        (this.searchField.date || this.$moment("dddd, MM-DD-YYYY"))
      );
    },
  },
};
</script>

<style scoped>
.shopping-cards div:last-child {
  padding-bottom: 20px;
}
</style>
